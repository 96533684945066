import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./Home";

import NotFoundError from "./NotFoundError";
import Guide from "./Guide";

const router = createBrowserRouter([
  {
    path: "",
    element: <Home/>
  },
  {
    path: "/guide",
    element: <Guide/>
  },
  {
    path: "*",
    element: <NotFoundError />
  }
]);

export default function Layout() {
  return <RouterProvider router={router} />;
}