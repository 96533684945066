import axios from "axios";
import { useState } from "react";
import { Container, H2, H4, Button, Input, Text, RadioButton, CopyableText } from "typetailui";
import { HTTP_METHODS } from "./constants";
import { Link } from "react-router-dom";
export default function Home() {
  const [selected, setSelected] = useState(0);

  const [url, setUrl] = useState("");
  const [status, setStatus] = useState("");
  const [result, setResult] = useState("");
  const [token, setToken] = useState("");
  const [body, setBody] = useState("");

  const onClick = async () => {
    try {
      let obj = null;
      if(selected !== 0){
        try {
          obj = JSON.parse(body);  
        } catch (error) {
          setStatus("Error");
          setResult("Invalid JSON");
          return;
        }
        
      }
      const res = await axios.request({
        url,
        method: HTTP_METHODS[selected],
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        data: obj
      });
      setResult(JSON.stringify(res.data));
      setStatus(JSON.stringify(res.status));
    } catch (error) {
      setStatus("Error");
      setResult(JSON.stringify(error));
      console.log("error ", error);
    }
  };
  const changeHttpMethod = (index: number) => {
    setSelected(index);
  }
  const changeBody = (e: any) => {
    setBody(e.target.value)
  }
  return (
    <Container className="flex flex-col space-y-2">
      <H2 className="text-center">Restful API Tester</H2>
      <H4 className="text-center italic">To test your RESTful APIs, please configure your backend to enable CORS for: https://restifyme.com. <Link to={"/guide"} className="text-blue-600 hover:text-blue-800 underline">Details</Link></H4>
      <Input
        type="text"
        label="URL:"
        value={url}
        onChangeText={text => setUrl(text)}
      />
      <Container className="flex flex-row space-x-1">
        {
        HTTP_METHODS.map((value, index) =>
          <RadioButton
            key={index}
            index={index}
            name={"httpmethod"}
            selected={index === selected}
            handleSelectionChange={index => changeHttpMethod(index)}
            label={value}
          />
        )}
      </Container>
      <Input
        type="text"
        label="Bearer Token:"
        value={token}
        placeholder="Enter token"
        onChangeText={text => setToken(text)}
      />
      {
      selected !== 0 && 
        <textarea rows={4} className="border-2" value={body} placeholder="Body in JSON" onChange={changeBody}/>
      }
      
      <Button title="Send" onClick={onClick} />
      <hr />
      <Text>
        Status: {status}
      </Text>
      <CopyableText title="Result" code={result}/>
      <hr/>
      <footer>
        <p>
          &copy; {new Date().getFullYear()} RestifyMe.Com. All rights reserved.
        </p>
      </footer>
    </Container>
  );
}
