import { Container, CopyableText } from "typetailui";
//import CopyableText from "./CopyableText";

export default function Guide() {
  const javaCode = `
    import org.springframework.context.annotation.Configuration;
    import org.springframework.web.servlet.config.annotation.CorsRegistry;
    import org.springframework.web.servlet.config.annotation.WebMvcConfigurer;

    @Configuration
    public class WebConfig implements WebMvcConfigurer {
        @Override
        public void addCorsMappings(CorsRegistry registry) {
            registry.addMapping("/**")
                    .allowedOrigins("https://restifyme.com/")
                    .allowedMethods("GET", "POST", "PUT", "DELETE")
                    .allowedHeaders("*")
                    .allowCredentials(true);
        }
    }
  `;
  const jsCode = `
  const express = require('express');
  const cors = require('cors');

  const app = express();
  const corsOptions = {
    origin: 'https://restifyme.com',
    optionsSuccessStatus: 200
  };
  
  app.use(cors(corsOptions));
  `

  return (
    <Container className="flex flex-col">
      <p className="text-lg mb-4">
        Please follow the instructions below to enable CORS for this site in your backend based on your backend technology.
      </p>
      <CopyableText title="Java Spring Boot" code={javaCode}/>
      <CopyableText title="NodeJS" code={jsCode}/>
      <hr/>
      <footer>
        <p>
          &copy; {new Date().getFullYear()} RestifyMe.Com. All rights reserved.
        </p>
      </footer>
    </Container>
  );
}
